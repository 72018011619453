import React, { FormEvent, useEffect } from "react"
import api from "../../services/api"
import { toast } from "react-toastify"

import { useGlobalState } from "../../common/globalState"
import { ITranslation } from "../../types/ITranslation"

import { capitalizeFirstAll } from "../../utilities/capitalizeFirst"
import { modelMap } from "../../common/constants"

import styles from '../../styles/TranslationsWidget.module.scss'

const translatePhrase = async (
    phrase: string, 
    targetLanguages: string[],
    model: string | undefined,
): Promise<ITranslation | null> => {
    if (!phrase) {
        toast.error('No text to translate!', { autoClose: 2000 })
        return null
    }
    if (!targetLanguages.length) {
        toast.error('No languages selected!')
        return null
    }
    const res = await api.post('/translations/translate_phrase', { phrase, targetLanguages, model })
    return res
}

type Props = {
    onCreate?: (translation: ITranslation) => void,
    allLanguages: any,
    lastUsedLanguages: string[],
    model: string,
}

const TranslationMaker = (props: Props) => {

    const { globalState, setGlobalState } = useGlobalState();

    const [selectedLanguages, setSelectedLanguages] = React.useState([] as any[])
    const [isTranslating, setIsTranslating] = React.useState(false)
    const [text, setText] = React.useState<string>('')

    const setLoading = (loading: boolean) => {
        setGlobalState({ loading })
        setIsTranslating(loading)
    }
    
    // call api to translate text
    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault?.()
        if (isTranslating || !text) return
        setLoading(true)
        const res = await translatePhrase(text, selectedLanguages, props.model)
        if (res) {
            setLoading(false)
            props.onCreate?.(res)
            setText('')
        } else {
            setLoading(false)
            toast.error('Translation failed!')
        }
    }

    // render multi select for languages
    const renderLanguageMultiSelect = () => {   

        // const lastUsedLanguages = tran
        
        const displayAllLanguages = props.allLanguages?.map(capitalizeFirstAll)
        const displaySelectedLanguages = selectedLanguages.map(capitalizeFirstAll)     
        return (<>
            <div className={styles.languageOptionButtons}>

                <button className='btn' onClick={() => {
                    setSelectedLanguages([])
                }}>Clear</button>

                <button className='btn' onClick={() => {
                    setSelectedLanguages(props.lastUsedLanguages)
                }}>Last Used</button>

                <button className='btn' onClick={() => {
                    setSelectedLanguages(props.allLanguages)
                }}>All</button>

            </div>

            <select multiple className={`input ${styles.multiLanguageSelect}`}
                value={displaySelectedLanguages}
                onChange={event => {
                    const languages = Array.from(event.target.selectedOptions, (item: any) => item.value)        
                    const newSelectedLanguages = languages.map((lang: string) => lang.toLowerCase())
                    setSelectedLanguages(newSelectedLanguages)
                }}gpt-
                style={{resize: 'vertical'}}
            >
                {displayAllLanguages?.map((lang: string, langIdx: number) => 
                    <option value={lang} key={`lang${langIdx}`}>{lang}</option>
                )}
            </select>
        </>)
    }

    return <>
        {renderLanguageMultiSelect()}

        <form className={`${styles.form}`} onSubmit={handleSubmit}>
            <h2>Text to translate:</h2>
            <textarea
                rows={5}
                className={`input ${styles.input} ${styles.translateTextArea} ${isTranslating ? styles.formBusy : ''}`}
                value={text}
                onChange={(e) => {
                    setText(e.target.value)
                }}
                style={{ height: 'auto' }}
                onKeyDown={(e) => {
                    // if shift enter
                    if (e.key === 'Enter' && e.ctrlKey) {
                        e.preventDefault()
                        handleSubmit((e as any) as FormEvent<HTMLFormElement>)
                    }
                }}
                disabled={isTranslating}
            />
            <br />
            <button disabled={isTranslating} className={`btn ${styles.button}`}>Translate</button>
        </form>
    </>

}

export default TranslationMaker