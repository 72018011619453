import React from "react";

import { ListResponse } from '../../../types/ListResponse'
import api from "../../../services/api";

import styles from './PaginatedList.module.scss'
import { get } from "http";
import addParamToUrl from "../../../utilities/addParamToUrl";
import PaginationCounter from "./PaginationCounter/PaginationCounter";

// This section is intended for the implementation of a dynamic pagination system for lists.
// The PaginatedList component is designed to fetch and display data in a paginated format.
// It utilizes an API call to retrieve items based on the current page and the specified route.
// The component accepts various props to customize its behavior and appearance:
// - title: A string to display as the title of the list.
// - route: The API endpoint from which to fetch the paginated data.
// - postProcess: An optional function to process the data after fetching but before rendering.
// - className: An optional CSS class to apply to the component for styling.
// - renderItem: A function that defines how to render each item in the list.
// - setCallables: An optional function to expose internal methods (like refreshData) to parent components.
// The component manages its own state for items, pagination, and loading status.
// It automatically updates the displayed items when the user navigates to a different page.
// The renderPagination function is responsible for rendering the pagination controls.


// Type definition for the component props
type Props = {
    title: string, // Title of the paginated list
    route: string, // API endpoint to fetch data from
    fetchData?: (skip: number, limit: number) => Promise<ListResponse>, // Optional function to fetch data
    postProcess?: (res: any[]) => any[], // Optional function to process data after fetching
    className?: string, // Optional CSS class for styling
    renderItem: (item: any) => any, // Function to render each item in the list
    setCallables?: (callables: any) => void, // Optional function to expose internal methods
    data?: any[] | null, // Optional Data to display in the list, will not fetch data if provided
}

// Default number of items to display per page
const DEFAULT_ITEMS_PER_PAGE = 10

// PaginatedList component definition
const PaginatedList = ({ 
    data, 
    route,
    fetchData, 
    ...props
}: Props) => {

    // State for managing items per page, items, total count, and current page
    const [itemsPerPage, setItemsPerPage] = React.useState(DEFAULT_ITEMS_PER_PAGE)
    const [items, setItems] = React.useState([] as any[])
    const [count, setCount] = React.useState(0)
    const [page, setPage] = React.useState(0)

    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        if (!data) return;
        setItems(data);
        setCount(data.length);
    }, [data]);

    // Effect hook to fetch data on component mount and when dependencies change
    React.useEffect(() => {
        if (data || fetchData) return

        const fetchDataFunction = async () => {
            if (loading) return
            setLoading(true)
            // Add page parameter to the API route
            const skip = page * itemsPerPage
            route = addParamToUrl(route, `skip=${skip}`)
            const res: ListResponse = await api.get(`${route}`)
            if (res?.success) {
                // Update state with fetched data
                setItems(res.items)
                setCount(res.count)
            }
            console.log('fetched data', []);
            setTimeout(() => setLoading(false), 50)
        }
        // Expose the fetchData function to parent component if setCallables is provided
        props.setCallables?.({
            refreshData: fetchDataFunction,
        })
        fetchDataFunction()
    }, [page, route])

    React.useEffect(() => {
        if (!fetchData) return
        fetchData(page * itemsPerPage, itemsPerPage)
            .then((res: ListResponse) => {
                if (res?.success) {
                    setItems(res.items)
                    setCount(res.count)
                }
            })
        
    }, [page, fetchData])

            
    // Function to render pagination controls
    const renderPagination = () => {
        return <PaginationCounter
            page={page}
            setPage={setPage}
            total={count}
            countPerPage={itemsPerPage}
        />
    }

    // Render the component
    return <div className={styles.main}>

        <h2 className={styles.translationListHeader}>
            {props.title}:
            {renderPagination()}
        </h2>

        {/* Render each item using the renderItem prop */}
        {items?.map((item: any) => props.renderItem(item))}
    </div>
}

export default PaginatedList