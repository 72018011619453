
import type { AxiosResponse, AxiosError, Axios } from 'axios';
import axios from 'axios';
import axiosRetry from 'axios-retry';

axiosRetry(axios, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

const isLocal = window.location.href.includes('localhost');

const local = window.location.href.includes('localhost');
const uiUrl = isLocal ? 'http://localhost:3000' : 'https://ui3.februarybluemoon.com'
const apiUrl = isLocal ? 'http://localhost:8000' : 'https://api3.februarybluemoon.com'

const X_USER_ID = 'X-User-Id'
const X_USER_TOKEN = 'X-User-Token'
const X_USER_EXPIRES_AT = 'X-User-Expires-At'

interface Headers {
    [X_USER_ID]?: string,
    [X_USER_TOKEN]?: string,
    [X_USER_EXPIRES_AT]?: Date,
}

let headers:  any = {}

const loadHeaders = () => {
    const auth = localStorage.getItem('AUTH')
    if (auth) {
        const authObject = JSON.parse(auth)
        headers = {
            ...headers,
            ...authObject
        }
    } else {
        headers = {}
        console.log('No auth found.');
        
    }
}

loadHeaders()

const getHeaders = () => {
    if (headers[X_USER_ID] && headers[X_USER_TOKEN] 
        && headers[X_USER_EXPIRES_AT] 
        && new Date(headers[X_USER_EXPIRES_AT]) > new Date()
    ) {
        return headers
    }
    return headers
}

// Errors must be a map of status codes to callbacks that accept the error response data
const makeRequest = async (request: ()=>any, errors: any = {}, throwOnError = false) => {
    try {
        const res: AxiosResponse = await request();
        // console.log('axios res:', res);
        return res.data;
    }
    catch (error: any | AxiosError) {
        console.log('API Request Error:', error);
        if (error.code === 'ERR_NETWORK') {
            console.log('Network error.');
        } else if (error.response?.data) {
            const isAxiosError = axios.isAxiosError(error)
            const status = Number(error.response.status);
            
            // Error callback exists? the keys are HTTP status codes
            if (status in errors) {
                errors[status](error.response.data) as any;
            }

        } else {
            // Invalid error object
            console.log("Invalid error object.");
            console.log(error);
        }
        if (throwOnError) {
            throw error;
        } else {
            return null;
        }
    }
}

const api = {
    local,
    uiUrl,
    apiUrl,
    loggedIn: () => {
        return headers[X_USER_ID] && headers[X_USER_TOKEN] && headers[X_USER_EXPIRES_AT] && new Date(headers[X_USER_EXPIRES_AT]) > new Date()
    },
    hasAuth: () => {
        return localStorage.getItem('AUTH') !== null
    },
    clear_auth: () => {
        localStorage.removeItem('AUTH')
        loadHeaders()
    },
    auth: (user: string, token: string, expiresAt: Date) => {
        const authObject = {
            [X_USER_ID]: user,
            [X_USER_TOKEN]: token,
            [X_USER_EXPIRES_AT]: expiresAt,
        }
        localStorage.setItem('AUTH', JSON.stringify(authObject))
        loadHeaders()
    },

    get: async (endpoint: string, errors: {} = {}, url?: string, throwOnError = false) => makeRequest(async () => await axios.get(`${url || apiUrl}${endpoint}`, { headers: getHeaders() }), errors, throwOnError),

    post: async (endpoint: string, data: {}, errors: {} = {}, url?: string, throwOnError = false) => makeRequest(async () => {
        return await axios.post(`${url || apiUrl}${endpoint}`, data, { headers: getHeaders() })
    }, errors, throwOnError),

    put: async (endpoint: string, data: {}, errors: {} = {}, url?: string, throwOnError = false) => makeRequest(async () => await axios.put(`${url || apiUrl}${endpoint}`, { headers: getHeaders() }, data), errors, throwOnError),

    delete: async (endpoint: string, errors: {} = {}, url?: string, throwOnError = false) => makeRequest(async () => await axios.delete(`${url || apiUrl}${endpoint}`, { headers: getHeaders() }), errors, throwOnError),

    patch: async (endpoint: string, data: {}, errors: {} = {}, url?: string, throwOnError = false) => makeRequest(async () => await axios.patch(`${url || apiUrl}${endpoint}`, { headers: getHeaders() }, data), errors, throwOnError),
}

export default api;