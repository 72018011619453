enum WhisperLanguage {
    None = 'none',
    English = 'en',
    Spanish = 'es',
    French = 'fr',
    German = 'de',
    Italian = 'it',
    Portuguese = 'pt',
    Dutch = 'nl',
    Russian = 'ru',
    ChineseSimplified = 'zh',
    ChineseTraditional = 'zh-tw',
    Japanese = 'ja',
    Korean = 'ko',
    Arabic = 'ar',
    Hindi = 'hi',
    Bengali = 'bn',
    Urdu = 'ur',
    Turkish = 'tr',
    Vietnamese = 'vi',
    Thai = 'th',
    Hebrew = 'he',
    Greek = 'el',
}

export default WhisperLanguage;