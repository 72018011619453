

export const modelMap: { 
    [key: string]: {
        label: string,
        value: string,
    }
} = {
    gpt35turbo: {
        label: 'GPT 3.5 Turbo',
        value: 'gpt-3.5-turbo',
    },
    gpt4: {
        label: 'GPT 4',
        value: 'gpt-4',
    },
    gpt4turbo: {
        label: 'GPT 4 Turbo',
        value: 'gpt-4-1106-preview',
    },
}