import { FormEvent, useState } from "react";
import api from "../services/api";

import { useGlobalState } from "../common/globalState";

type Props = {

}

const GptQuestion = ({}: Props) => {

    const { globalState, setGlobalState } = useGlobalState();

    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const [error, setError] = useState(null as null | string)

    // Handle form submission
    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault(); // Prevent default form submission behavior
        // console.log('Submitted:', { name, password });
        // Here you would usually send the data to the server, etc.

        
        const res = await api.post('/gpt/question', {
            question,
        })


        if (res && res.answer) {
            setAnswer(res.answer)
            setError(null)
        } else {
            setError('No answer')
        }
        
    };

    return (
        <form onSubmit={handleSubmit}>

            <div>
                <label htmlFor="question">Question:</label>
                <input
                    type="text"
                    id="question"
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                />
            </div>

            {error && <div>{error}</div>}

            {answer && <div>{answer}</div>}
            
            <button type="submit">Ask Question</button>
        </form>
    );
};

export default GptQuestion;