import getBaseResourceCall from './helpers/getBaseResourceCall';
import { routeConstants } from './helpers/constants';
import api from '../services/api';
import WhisperMode from '../types/Transcribe/WhisperMode';
import WhisperLanguage from '../types/WhisperLanguage';
import { toQueryString } from '../utilities/apiHelpers/queryString';

const baseCalls = getBaseResourceCall(routeConstants.transcriptions, api);

export interface TranscribeOptions {
    targetLanguage?: string;
    mode: WhisperMode;
}

const transcribeBlob = async (
    audioBlob: Blob,
    mode: WhisperMode = WhisperMode.Transcribe,
    targetLanguage?: WhisperLanguage,
    ): Promise<any> => {
    try {
        const formData = new FormData();
        formData.append('file', audioBlob, 'audio.wav');
        formData.append('mode', mode);
        if (mode === WhisperMode.Translate && targetLanguage) {
            formData.append('target_language', targetLanguage as string);
        }
        const transcription = (await api.post('/whisper/transcribe', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })).transcription;
        return {
            blob: audioBlob,
            text: transcription,
        }
    } catch (error) {
        console.error('Error transcribing audio:', error);
        throw error;
    }
};

const getKey = async (password: string): Promise<string> => {
    try {
        const route = `/whisper/key?${toQueryString({ password })}`;
        const res = await api.get(route)
        const key = (res).key as string;
        return key;
    } catch (error) {
        console.error('Error getting key:', error);
        throw error;
    }
}

export default {
    // ...baseCalls,
    transcribeBlob,
    getKey,
} as {
    transcribeBlob: (audioBlob: Blob) => Promise<{
        blob: Blob,
        text: string,
    }>,
    getKey: (password: string) => Promise<string>,
} | Record<string, any>;
