


export enum TranslationStatus {
    PENDING = 'pending',
    COMPLETE = 'complete',
    ERROR = 'error',
}

export interface ITranslationBreakdown {
    word: string;
    translation: string;
    pronunciation: string;
}

export interface ISingleTranslation {
    language: string;
    requestedLanguage: string;
    isCorrectLanguage: boolean;
    raw_response: string;
    translation: string;
    breakdown: ITranslationBreakdown[];
}

export interface ITranslation {
    _id: string;
    status: TranslationStatus;
    user: string;
    phrase: string;
    phrase_language: string;
    languages: string[];
    model: string;
    raw_response: string;
    translations: ISingleTranslation[];
    ellapsed_ms: number;
    created_at: Date;
    deleted_at?: Date;
}