

import React, { useEffect } from 'react'
import { toast } from 'react-toastify'

import api from '../../services/api'
import { useGlobalState } from '../../common/globalState'

import { ISingleTranslation, ITranslation, TranslationStatus } from '../../types/ITranslation'
import Select from 'react-select'
import { modelMap } from '../../common/constants'
import PaginatedList from '../Table/PaginatedList'
import addParamToUrl from '../../utilities/addParamToUrl'

import styles from './VoiceTranslator.module.scss'
import PaginationCounter from '../Table/PaginatedList/PaginationCounter/PaginationCounter'
import useFetchUpdatedTranslations from '../../hooks/useFetchUpdatedData'
import WhisperWidget from '../Whisper/WhisperWidget/WhisperWidget'

const TRAN_PER_PAGE = 10

const VoiceTranslator = () => {

    const { globalState, setGlobalState } = useGlobalState();

    const [languageOptions, setLanguageOptions] = React.useState({} as any)
    const [translationList, setTranslationList] = React.useState([] as any[])
    useFetchUpdatedTranslations(translationList, setTranslationList)

    const [refreshData, setRefreshData] = React.useState(null as any)

    const getRoute = () => {
        const userId = globalState.user?._id
        if (!userId) return ''
        return `/translations?user=${userId}`
    }

    return (
        <main className={styles.main}>

            <header className={styles.header}>
                <h1>Whisper</h1>
            </header>

            <WhisperWidget />

        </main>
    )
}

export default VoiceTranslator