
// Importing necessary modules and components
import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import api from '../../services/api'
import { useGlobalState } from '../../common/globalState'
import SingleTranslation from './SingleTranslation'
import TranslationMaker from './TranslationMaker'
import { ISingleTranslation, ITranslation, TranslationStatus } from '../../types/ITranslation'
import Select from 'react-select'
import { modelMap } from '../../common/constants'
import PaginatedList from '../Table/PaginatedList'
import addParamToUrl from '../../utilities/addParamToUrl'
import styles from '../../styles/TranslationsWidget.module.scss'
import PaginationCounter from '../Table/PaginatedList/PaginationCounter/PaginationCounter'
import useFetchUpdatedTranslations from '../../hooks/useFetchUpdatedData'

// Constants
const TRAN_PER_PAGE = 10

// TranslationsWidget component
const TranslationsWidget = () => {
    // State management hooks
    const { globalState, setGlobalState } = useGlobalState();
    
    const [languageOptions, setLanguageOptions] = React.useState({} as any)
    const [translationList, setTranslationList] = React.useState([] as any[])
    useFetchUpdatedTranslations(translationList, setTranslationList)

    const [model, setModel] = React.useState(modelMap.gpt35turbo)
    
    const [refreshDataFunc, setRefreshDataFunc] = React.useState<(() => void) | null>(null)

    const refreshData = () => {
        if (refreshDataFunc && typeof refreshDataFunc === 'function') {
            refreshDataFunc()
        }
    }

    // Helper function to construct API route
    const getRoute = () => {
        const userId = globalState.user?._id
        return userId ? `/translations?user=${userId}` : ''
    }

    // Fetch available language options
    useEffect(() => {
        if (!globalState.user?._id) return

        const fetchLanguageOptions = async () => {
            const languagesRes = await api.get('/translations/languages')
            console.log('languagesRes:', languagesRes);
            return languagesRes
        }
        
        (async () => {
            setGlobalState({ loading: true })
            const languageOptions = await fetchLanguageOptions()
            setLanguageOptions(languageOptions)
            setGlobalState({ loading: false })
        })()
    }, [globalState.user])

    // Check for updates in translations status
    // useEffect(() => {
    //     // Set an interval to periodically check for updates in the status of pending translations
    //     const intervalId = setInterval(async () => {
    //         // Log the current global state for debugging purposes
    //         console.log('global state', globalState);
    //         // Create a copy of the current translations list
    //         const curTranslations = [...translationList]
    //         // Filter out translations that are still pending
    //         const pendingTranslations = curTranslations.filter((t: ITranslation) => t.status === TranslationStatus.PENDING)
    //         // If there are no pending translations, exit early
    //         if (!pendingTranslations.length) {
    //             clearInterval(intervalId);
    //             return;
    //         }
    //         // Log checking action for debugging
    //         console.log('checking for new translations');
    //         // Flag to track if any translations have been updated
    //         let unchanged = true
    //         // Check each pending translation for updates
    //         await Promise.all(pendingTranslations.map(async (t: ITranslation) => {
    //             // Fetch the latest status of the translation
    //             const res = await api.get(`/translations/${t._id}`)
    //             // If the translation status has changed from pending
    //             if (res?.status !== TranslationStatus.PENDING) {
    //                 // Find the index of the current translation in the list
    //                 const curTran = curTranslations.findIndex((ct: ITranslation) => ct._id === t._id)
    //                 // If found, update the translation in the list
    //                 if (curTran !== -1) {
    //                     curTranslations[curTran] = res
    //                     // Mark that changes have occurred
    //                     unchanged = false
    //                 }
    //             }
    //         }))
    //         // If any translations were updated, set the new list
    //         if (!unchanged) {
    //             setTranslationList(curTranslations)
    //         }
    //     }, 2000) // Check every 2000 milliseconds (2 seconds)

    //     return () => clearInterval(intervalId)
        
    // }, [translationList])

    // Determine the last used languages for the translation form
    const lastUsedLanguages = translationList?.[0]?.translations?.map(
        (t: ISingleTranslation) => t.language
    ) || []

    // Main render method
    return (
        <main className={styles.main}>

            {/* title and model selection */}
            <header className={styles.header}>
                <h1>Translations</h1>

                <div style={{height: '40px'}} />

                <Select 
                    className={styles.modelDropdown}
                    options={Object.values(modelMap)} 
                    placeholder="Select a model"
                    value={model}
                    onChange={(newModel: any) => {
                        console.log('newModel:', newModel);
                        setModel(newModel)
                    }}
                />
            </header>

            {/* language selector, text area, and translate button */}
            {globalState.signedIn && <TranslationMaker
                onCreate={async (newTranslation: any) => {
                    setTranslationList([newTranslation, ...translationList])
                    refreshData()
                }}
                allLanguages={Object.keys(languageOptions?.languageMap || {})}
                lastUsedLanguages={lastUsedLanguages}
                model={model.value as string}
            />}

            {/* List of translations */}
            <PaginatedList
                route={getRoute()}
                className={styles.pagination}
                title={`Translations`}
                renderItem={(tran: any) => {
                    return <SingleTranslation 
                        translation={tran}
                        languageMap={languageOptions?.languageMap}
                        onDelete={(tranId: string) => {
                            const newTranslations = translationList.filter(t => t._id !== tranId)
                            setTranslationList(newTranslations)
                            refreshData()
                        }}
                    />
                }}
                setCallables={ (callables: any) => {
                    if (callables.refreshData) {
                        setRefreshDataFunc(callables.refreshData)
                    }
                }}
            />

        </main>
    )
}

export default TranslationsWidget