
export const speakText = (text: string, languageCode: string = 'en-US'): void => {
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.lang = languageCode
    const voice = speechSynthesis.getVoices().find((voice) => voice.lang === languageCode);
    if (voice) {
        utterance.voice = voice;
    }

    console.log(`Speaking: "${text}" in ${languageCode}`);

    const voices = speechSynthesis.getVoices()
    console.log('Voices:', voices);    
  
    // Cancel the previous speech
    speechSynthesis.cancel();
    speechSynthesis.speak(utterance);
}