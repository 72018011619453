import { useNavigate } from 'react-router-dom'

import { useGlobalState } from '../common/globalState'

import styles from '../styles/MainMenu.module.scss'

type Props = {
    close: () => void,
}

const MainMenu = ({ close }: Props) => {

    const navigate = useNavigate()

    const { globalState, setGlobalState } = useGlobalState();

    const isSignedIn = !!globalState.user?.name
    const isAdmin = globalState.user?.isAdmin

    const links = [
        { text: 'Home', path: '/' },
    ]

    const renderLink = (link: any) => {
        return <button className={`btn ${styles.link}`} onClick={() => {
            navigate(link.path)
            close()
        }}>{link.text}</button>
    }

    return (<div className={styles.main}>
        <h1>Main Menu!</h1>
        {globalState.pages.filter(p => p.show).sort((a, b) => (a.zIndex || 0) - (b.zIndex || 0)).map((page: any) => {
            return renderLink({
                text: page.name,
                path: page.path,
            })
        })}
    </div>)

}

export default MainMenu