import React, { useState } from 'react';
import api from '../../../services/api';

import styles from './SignupForm.module.scss';
import { toast } from 'react-toastify';

type Props = {
    close: (success: boolean | null) => void,
}

const SignupForm = (props: Props) => {
    const [name, setName] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        console.log('Signup details:', { name, password });
        // Here, you would typically make an API call to your server to register the user

        const createUserRes = await api.post('/users', { name, password }, {
            400: (err: any) => {
                console.log('err:', err);
                toast.error(`User creation failed! ${err?.message}`)
            },
            409: (err: any) => {
                console.log('err:', err);
                toast.error(`User creation failed! ${err?.message}`)
            },
            500: (err: any) => {
                console.log('err:', err);
                toast.error(`User creation failed! ${err?.message}`)
            },
        });
        console.log('createUserRes:', createUserRes);

        if (createUserRes?.success) {
            toast.success(`User created successfully!`)
            props.close?.(true)
        } else {
            // toast.error(`User creation failed!`)
        }

        
    };

    return (
        <form className={styles.form} onSubmit={handleSubmit}>

            <h1 onClick={() => props.close?.(null)}>Create User</h1>
            
            <label className={`label ${styles.label}`} htmlFor="name">Name:</label>
            <input
                className={`input ${styles.input}`}
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
            />
            <label className={`label ${styles.label}`} htmlFor="password">Password:</label>
            <input
                className={`input ${styles.input}`}
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <button className='btn'>Create User</button>
        </form>
    );
};

export default SignupForm;
