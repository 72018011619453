

import React, { useState } from 'react';
import styles from '../../styles/Assistant.module.scss'; // Assuming similar styling to TranslationsWidget

type InputField = {
    title: string;
    description: string;
    id: string;
};

const inputFields: InputField[] = [
    { title: 'Resume', description: 'Description for title 1', id: 'title1' },
    { title: 'Job Description', description: 'Description for title 2', id: 'title2' },
    { title: 'Additional Information', description: 'Description for title 2', id: 'title2' },
    // Add more fields as needed
];

const Assistant = () => {
    const [inputValues, setInputValues] = useState<{ [key: string]: string }>({});
    const [submissions, setSubmissions] = useState<string[]>([]);

    const handleChange = (id: string, value: string) => {
        setInputValues({ ...inputValues, [id]: value });
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        setSubmissions([...submissions, JSON.stringify(inputValues)]);
        setInputValues({}); // Reset input fields after submission
    };

    return (
        <div className={styles.assistantContainer}>
            <form onSubmit={handleSubmit} className={styles.assistantForm}>
                {inputFields.map((field) => (
                    <div key={field.id} className={styles.inputGroup}>
                        <h3>{field.title}</h3>
                        <p>{field.description}</p>
                        <textarea
                            value={inputValues[field.id] || ''}
                            onChange={(e) => handleChange(field.id, e.target.value)}
                            className={styles.textArea}
                        />
                    </div>
                ))}
                <button type="submit" className={styles.submitButton}>Submit</button>
            </form>
            <aside className={styles.submissionsList}>
                <h3>Previous Submissions</h3>
                <ul>
                    {submissions.map((submission, index) => (
                        <li key={index}>{submission}</li>
                    ))}
                </ul>
            </aside>
        </div>
    );
};

export default Assistant;
