
import React from 'react'

import styles from './PaginationCounter.module.scss'

interface PaginationCounterProps {
    page: number
    setPage: (page: number) => void
    total: number
    countPerPage: number
}

const PaginationCounter = ({
    page,
    setPage,
    total,
    countPerPage,
}: PaginationCounterProps) => {
    const start = (page)*countPerPage+1
    const end = Math.min(total, (page+1)*countPerPage)
    const numPages = Math.ceil(total/countPerPage) || 1
    const prevPage = Math.max(0, page-1)
    const nextPage = Math.min(page+1, numPages - 1)
    console.log('pag', start, end, numPages, prevPage, nextPage);
    const hasPrev = page > 0
    const hasNext = page < numPages - 1
    
    return (<>
        <span className={styles.count}>
            {/* right and left arrow for pagination */}
            <span className={styles.pagination}>
                <span 
                    className={hasPrev ? styles.arrow : styles.arrowDisabled}
                    onClick={hasPrev ? () => setPage(prevPage) : () => {}}
                >◀</span>
                ({start}-{end} of {total})
                <span 
                    className={hasNext ? styles.arrow : styles.arrowDisabled}
                    onClick={hasNext ? () => setPage(nextPage) : () => {}}
                >▶</span>
            </span>                    
        </span>
    </>)
}

export default PaginationCounter