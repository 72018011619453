import React, { useEffect } from 'react'

import styles from './WhisperWidget.module.scss'
import WhisperTranscriber from '../WhisperTranscriber/WhisperTranscriber'
import { useGlobalState } from '../../../common/globalState'
import PaginatedList from '../../Table/PaginatedList'
import apiClient from '../../../apiClient'
import { toast } from 'react-toastify'
import { ITranscription } from '../../../types/ITranscription'

const WhisperWidget = ({

}) => {

    const { globalState, setGlobalState } = useGlobalState()

    const [loading, setLoading] = React.useState(false)
    const [transcriptions, setTranscriptions] = React.useState([] as ITranscription[])

    const fetchTranscriptions = async () => {
        if (loading) return
        setLoading(true)
        try {
            const transcriptions = (await apiClient.transcriptions.paginateList({
                user: globalState.user?._id,
            }, 10,))?.items as ITranscription[]
            setTranscriptions(transcriptions)
        } catch (err) {
            toast.error('Error fetching transcriptions', (err as any)?.message)
            console.error('Error fetching transcriptions:', err)
        }
        setLoading(false)
    }

    // load transcriptions
    useEffect(() => {
        fetchTranscriptions()
    }, [])

    return (
        <div className={styles.main}>
            <WhisperTranscriber
                onSaveTranscript={async (transcript, languageCode, translated) => {
                    // save transcription
                    try {
                        const transcription = (await apiClient.transcriptions.create({
                            text: transcript.text,
                            language: languageCode,
                            translated: translated,
                        })).transcription as ITranscription
                        setTranscriptions([transcription, ...transcriptions])
                    } catch (err) {
                        toast.error('Error saving transcription', (err as any)?.message)
                        console.error('Error saving transcription:', err)
                    }
                }}
            />

            <hr />
            <hr />
            <hr />
            <hr />

            {transcriptions.length === 0
                ? <div className={styles.centerMessage}>No transcriptions</div>
                : <div>
                    <h2>Transcriptions</h2>
                    {transcriptions.map((transcription, i) => {
                        return <div key={i} className={styles.transcript}>
                            <hr />
                            {transcription.text}
                        </div>
                    })}
                </div>
            }

            {/* <PaginatedList
                fetchData={(skip, limit) => {

                }}
                className={styles.pagination}
                title={`Translations`}
                renderItem={(tran: any) => {
                    return <SingleTranslation
                        translation={tran}
                        languageMap={languageOptions?.languageMap}
                        onDelete={(tranId: string) => {
                            const newTranslations = translationList.filter(t => t._id !== tranId)
                            setTranslationList(newTranslations)
                            refreshData()
                        }}
                    />
                }}
                setCallables={(callables: any) => {
                    if (callables.refreshData) {
                        setRefreshDataFunc(callables.refreshData)
                    }
                }}
            /> */}
        </div>
    )
}

export default WhisperWidget