
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useGlobalState } from "../../../common/globalState"
import LoginForm from "../LoginForm/LoginForm";
import api from "../../../services/api";
import SignupForm from "../SignupForm/SignupForm";

import styles from './SessionWidget.module.scss'
import { toast } from "react-toastify";


type Props = {
    close: () => void,
    straightToLogin?: boolean,
}

const SessionWidget = (props: Props) => {

    const navigate = useNavigate()

    const { globalState, setGlobalState } = useGlobalState();
    const [showLoginForm, setShowLoginForm] = useState(false)
    const [showSignupForm, setShowSignupForm] = useState(false)
    
    const username = globalState.user?.name
    const isAdmin = globalState.user?.isAdmin
    const isSignedIn = !!username

    useEffect(() => {
        if (props.straightToLogin && !isSignedIn) {
            setShowLoginForm(true)
        }
    }, [])

    const renderLoginForm = () => {
        if (username || !showLoginForm) return null
        return <div className={styles.form}>
            <LoginForm 
                onSuccess={(user) => {
                    setGlobalState({ user })
                    setShowLoginForm(false)
                    props.close?.()
                    toast.success(`Welcome ${user.name}!`)
                }}
            />
        </div>
    }

    const renderSignupForm = () => {
        if (!isAdmin || !showSignupForm) return null
        return <SignupForm
            close={(success: boolean | null) => {
                setShowSignupForm(false)
                if (success === true) {
                    props.close?.()
                }
            }}
        />
    }

    const renderSignupButton = () => {
        if (!isAdmin || showSignupForm) return null
        return <button className={`btn ${styles.link}`} onClick={() => setShowSignupForm(true)}>Signup</button>
    }

    const renderLoginButton = () => {
        if (username || showLoginForm) return null
        return <button className={`btn ${styles.link}`} onClick={() => setShowLoginForm(true)}>Login</button>
    }

    const renderLogoutButton = () => {
        if (!username) return null
        return <button className={`btn ${styles.link}`} onClick={async () => {
            await api.post('/users/logout', {})
            setGlobalState({ user: null })
            toast.success(`See you later ${username}!`)
            navigate('/')
            props.close?.()
        }}>Logout</button>
    }

    const renderGreetings = () => {
        if (!username) return null
        return <h1>{`Hello ${username}!`}</h1>
    }

    const renderMenu = () => {

        return (<div className={styles.main}>
            {renderGreetings()}
            {renderLoginButton()}
            {renderLogoutButton()}
            {renderSignupButton()}
            {renderLoginForm()}
            {renderSignupForm()}
        </div>)
    }

    return (
        <div className={styles.main}>
            {renderMenu()}
        </div>
    )
}

export default SessionWidget