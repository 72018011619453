import React from 'react';
import styles from './PlaybackControls.module.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faStop, faMicrophone, faSave } from '@fortawesome/free-solid-svg-icons';

interface PlaybackControlsProps {
    started?: boolean;
    paused?: boolean;
    hasAudio?: boolean;
    onStartClick?: () => void;
    onPauseClick?: () => void;
    onStopClick?: () => void;
    onSaveClick?: () => void;
    recordMode?: boolean;
    hasRecordedContent?: boolean;
}

const PlaybackControls: React.FC<PlaybackControlsProps> = ({ 
    started = false,
    paused = false,
    hasAudio = false,
    onStartClick = () => {}, 
    onPauseClick = () => {}, 
    onStopClick = () => {},
    onSaveClick,
    recordMode = false,
    hasRecordedContent = false,
}) => {

    const stopped = !started && !paused;
    const showStartButton = stopped || paused;
    const showSaveButton = !!onSaveClick

    return (
        <div className={[
            styles.main,
        ].join(' ')}>
            <div className={[
                styles.controls,
                stopped ? styles.stopped : '',
                started ? styles.started : '',
                paused ? styles.paused : '',
                hasAudio ? styles.hasAudio : '',
            ].join(' ')}>
                {showStartButton ? (
                    <button className={styles.startButton} onClick={onStartClick}>
                        <FontAwesomeIcon 
                            icon={recordMode ? faMicrophone : faPlay} 
                        />
                    </button>
                ) : (
                    <button className={styles.pauseButton} onClick={onPauseClick}>
                        <FontAwesomeIcon 
                            icon={faPause} 
                        />
                    </button>
                )}
                {!showSaveButton ? (
                    <button className={`${styles.stopButton}`} onClick={onStopClick}>
                        <FontAwesomeIcon 
                            icon={faStop} 
                        />
                    </button>
                ) : (
                    <button className={`${styles.saveButton}`} onClick={onSaveClick}>
                        <FontAwesomeIcon 
                            icon={faSave} 
                        />
                    </button>
                )}
            </div>
        </div>
    );
};

export default PlaybackControls;


