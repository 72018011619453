import React from 'react'
import { useWhisper } from '../../../hooks/useWhisper'

import PlaybackControls from '../PlaybackControls/PlaybackControls'

import styles from './WhisperTranscriber.module.scss'
import { useGlobalState } from '../../../common/globalState'
// import { Language as WhisperLanguage } from '../languages'
import WhisperLanguage from '../../../types/WhisperLanguage'
import LanguageSelect from '../LanguageSelect/LanguageSelect'
import { UseWhisperConfig } from '../../../hooks/useWhisper/types'
import apiClient from '../../../apiClient'
import WhisperMode from '../../../types/Transcribe/WhisperMode'
import { toast } from 'react-toastify'

const USE_BACKEND = true

// This is responsible for recording and transcribing the recording. It then calls onTranscribe function with the finished transcription, which will pass the new transcription to the parent.

const getKey = async (password: string) => {
    try {
        const key = await apiClient.whisper.getKey(password)
        return key
    } catch (error) {
        toast.error('Error getting key')
        console.error('Error getting key:', error)
    }
}

const onTranscribe0 = async (blob: Blob) => {
    const base64 = await new Promise<string | ArrayBuffer | null>(
        (resolve) => {
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result)
            reader.readAsDataURL(blob)
        }
    )
    const body = JSON.stringify({ file: base64, model: 'whisper-1' })
    const headers = { 'Content-Type': 'application/json' }
    const { default: axios } = await import('axios')
    const response = await axios.post('/api/whisper', body, {
        headers,
    })
    const { text } = await response.data
    // you must return result from your server in Transcript format
    return {
        blob,
        text,
    }
}

const onTranscribe = async (
    audioBlob: Blob,
    mode: WhisperMode = WhisperMode.Transcribe,
    targetLanguage?: WhisperLanguage
) => {
    return await apiClient.whisper.transcribeBlob(audioBlob, mode, targetLanguage)
};

export interface WhisperTranscript {
    blob: Blob;
    text: string;
}

const WhisperTranscriber: React.FC<{
    onSaveTranscript?: (
        transcript: WhisperTranscript,
        language: WhisperLanguage | undefined,
        translated?: boolean,
    ) => (Promise<void> | void);
    showSettings?: boolean,
    showTranscript?: boolean,
}> = ({
    onSaveTranscript,
    showSettings = true,
    showTranscript = true,
}) => {

        const { globalState, setGlobalState } = useGlobalState()

        const key = globalState.key

        const [languages, setLanguages] = React.useState([] as any[])
        const [language, setLanguage] = React.useState(WhisperLanguage.None)
        const [target_language, setTarget_language] = React.useState(WhisperLanguage.English)
        const [translate, setTranslate] = React.useState(false)
        const [mode, setMode] = React.useState(WhisperMode.Translate)
        const toggleMode = () => setMode(mode === WhisperMode.Transcribe ? WhisperMode.Translate : WhisperMode.Transcribe)

        let [useBackend, setUseBackend] = React.useState(USE_BACKEND)
        useBackend = !key ? true : useBackend

        const [paused, setPaused] = React.useState(false)

        const [saveNextTranscript, setSaveNextTranscript] = React.useState(false)
        const [readyToSave, setReadyToSave] = React.useState(false)

        // onTranscribe: ({ blob } => {
        //     return { blob, text }
        // }

        // if key is not set, try to get it from session storage
        React.useEffect(() => {
            if (key) return
            const sessionStorageKey = sessionStorage.getItem("key");
            if (sessionStorageKey) {
                setGlobalState({ key: sessionStorageKey });
            }
        }, [key, setGlobalState]);

        let whisperConfig = {
            // ...language !== WhisperLanguage.None ? { language } : {},
            //     prompt: 'previous conversation', // you can pass previous conversation for context
            //     response_format: 'text', // output text instead of json
            //     temperature: 0.8, // random output
            ...target_language !== WhisperLanguage.None ? { target_language } : {},
        }

        const whisperOptions: UseWhisperConfig = {
            // use onTranscribe if you want to transcribe on the backend, otherwise use apiKey
            ...useBackend ? {
                onTranscribe: (blob) => onTranscribe(blob, mode, target_language),
            } : {
                apiKey: key,
            },
            whisperConfig,
            // whisperConfig: {
            //     ...whisperConfig,
            //     ...target_language !== WhisperLanguage.None ? { target_language } : {},
            // },
            // apiKey: key, // YOUR_OPEN_AI_TOKEN
            // onTranscribe: (blob) => onTranscribe(blob, mode, target_language),
            mode: mode === WhisperMode.Translate ? 'translations' : 'transcriptions',
            // mode: 'transcriptions',
            // mode: 'translations',
            removeSilence: true, // remove silence from the audio
            streaming: true, // real-time streaming
            timeSlice: 1_000, // 1 second
            // autoStart: true, // will auto start recording speech upon component mounted
            // nonStop: true, // keep recording as long as the user is speaking
            // stopTimeout: 5000, // auto stop after 5 seconds
            // autoTranscribe: true, // not sure what this does

            // onTranscribe: ({ blob } => {
            //     return { blob, text }
            // }
        }

        // return <div>WhisperTranscriber</div>
        const {
            recording,
            speaking,
            transcribing,
            transcript,
            pauseRecording,
            startRecording,
            stopRecording,
        } = useWhisper(whisperOptions)

        const started = recording || paused

        // for saving in parent - not done, don't know what to do with the transcript
        React.useEffect(() => {
            if (!saveNextTranscript) return
            if (transcribing) return
            setSaveNextTranscript(false)
            if (!transcript.text) return
            setReadyToSave(true)
        }, [transcribing, transcript.text, onSaveTranscript, saveNextTranscript]);

        const modeName = Object.keys(WhisperMode).find((key) => WhisperMode[key as keyof typeof WhisperMode] === mode) || 'Unknown'

        return (
            <div className={[
                styles.main,
                transcribing ? styles.transcribing : '',
            ].join(' ')}>

                <button style={{
                    margin: '0.5rem',
                    padding: '0.2rem 0.4rem',
                    fontSize: '0.7rem',
                    border: '1px solid #ccc',
                }} onClick={async () => {
                    // if trying to switch to frontend, get key by password
                    if (useBackend) {
                        const password = prompt('Enter your password')
                        if (password) {
                            const key = await getKey(password)
                            if (key) {
                                // set key in global state, and save in session storage
                                setGlobalState({ key })
                                sessionStorage.setItem("key", key)
                                setUseBackend(false)
                            }
                        }
                    }
                    setUseBackend(!useBackend)
                }}>
                    {useBackend ? 'Using Backend, Use Frontend Instead?' : 'Using Frontend, Use Backend Instead?'}
                </button>

                <button style={{
                    margin: '0.5rem',
                    padding: '0.2rem 0.4rem',
                    fontSize: '0.7rem',
                    border: '1px solid #ccc',
                }} onClick={toggleMode}>
                    {mode === WhisperMode.Transcribe ? 'Translate' : 'Transcribe'} instead
                </button>

                <h1>{modeName} Mode</h1>

                {/* if they figure out translating to languages other than english, uncomment this */}
                {/* {mode === WhisperMode.Translate && <div
                    className={styles.horizontalLabelItem}
                    style={{
                        fontSize: 'small',
                        gap: '0.5rem',
                    }}
                >
                    <label>Target Language</label>
                    <LanguageSelect
                        language={target_language}
                        onChange={setTarget_language}
                        allowNone={false}
                    />
                </div>} */}

                <div style={{ fontSize: 'small' }}>
                    <p>Mode: {mode}</p>
                    <p>Target Language: {target_language !== WhisperLanguage.None ? target_language : 'None'}</p>
                    <p>Language: {language !== WhisperLanguage.None ? language : 'None'}</p>
                    <p>Transcribing: {transcribing ? 'Yes' : 'No'}</p>

                    {/* <p>Remove Silence: {whisperOptions.removeSilence ? 'Yes' : 'No'}</p> */}
                    <p>Streaming: {whisperOptions.streaming ? 'Yes' : 'No'}</p>
                    <p>Time Slice: {whisperOptions.timeSlice} ms</p>
                    <p>Use Backend: {useBackend ? 'Yes' : 'No'}</p>
                </div>

                <div className={`${styles.controls} ${transcribing ? styles.transcribing : ''}`}>
                    <PlaybackControls
                        recordMode={true}
                        hasRecordedContent={!!transcript?.text}
                        started={started}
                        paused={paused}
                        hasAudio={speaking}
                        onStartClick={() => {
                            startRecording()
                            setPaused(false)
                            setReadyToSave(false)
                        }}
                        onPauseClick={() => {
                            pauseRecording()
                            setPaused(!paused)
                            setReadyToSave(false)
                        }}
                        onStopClick={() => {
                            if (!started) return
                            stopRecording()
                            setPaused(false)
                            setReadyToSave(false)
                            setTimeout(() => setSaveNextTranscript(true), 4000)
                        }}
                        onSaveClick={!readyToSave ? undefined : async () => {
                            if (transcript.text) {
                                await onSaveTranscript?.(transcript as WhisperTranscript, language, translate);
                                setReadyToSave(false)
                            }
                        }}
                    />
                </div>

                {showTranscript && <div className={styles.text}>{
                    !showTranscript
                        ? 'No text transcribed yet'
                        : transcribing
                            ? 'Transcribing...'
                            : (transcript?.text || 'No text transcribed yet')
                }</div>}
            </div>
        )
    }

export default WhisperTranscriber