import { FormEvent, useState } from "react";
import api from "../../../services/api";

import { useGlobalState } from "../../../common/globalState";

import '../../../styles/global.scss'
import styles from './LoginForm.module.scss'

type LoginStatus = null | 'bad request' | 'not found' | 'success' | 'forbidden' | 'locked' | 'server error'

type Props = {
    onSuccess?: (user: any) => void,
}

const LoginForm = (props: Props) => {

    const { globalState, setGlobalState } = useGlobalState();
    
    // State for name and password
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');

    const [loginStatus, setLoginStatus] = useState(null as null | LoginStatus )

    // Handle form submission
    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault(); // Prevent default form submission behavior
        // console.log('Submitted:', { name, password });
        // Here you would usually send the data to the server, etc.

        const loginRes: {
            user: any,
            token: string,
            expiresAt: Date,
            key?: string,
        } = await api.post('/users/login', { name, password }, {
            400: (err: any) => {
                console.log('err:', err);
                setLoginStatus('bad request')
            },
            401: (err: any) => {
                console.log('err:', err);
                setLoginStatus('forbidden')
            },
            403: (err: any) => {
                console.log('err:', err);
                setLoginStatus('locked')
            },
            404: (err: any) => {
                console.log('err:', err);
                setLoginStatus('not found')
            },
            500: (err: any) => {
                console.log('err:', err);
                setLoginStatus('server error')
            }
        });

        if (loginRes && loginRes.user && loginRes.token && loginRes.expiresAt) {
            api.auth(loginRes.user._id, loginRes.token, loginRes.expiresAt)
            props.onSuccess?.(loginRes.user)
            setLoginStatus('success')
        }        
    };

    return (
        <form className={styles.form} onSubmit={handleSubmit}>

            <h1>Login</h1>

            <label className={`label ${styles.label}`} htmlFor="name">Name:</label>
            <input className={`input ${styles.input}`}
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
            />

            <label className={`label ${styles.label}`} htmlFor="password">Password:</label>
            <input className={`input ${styles.input}`}
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />

            <button className="btn">Login</button>
        </form>
    );
};

export default LoginForm;