import React, { createContext, useContext, useState, ReactNode } from 'react';
import { IUser } from '../types/IUser';

type Page = {
    name: string;
    path: string;
    show: boolean;
    component?: React.ComponentType<any>;
    zIndex?: number;
};


type GlobalStateType = {
    user: IUser | null;
    signedIn: boolean;
    apiHealth: any;
    theme: string;
    loading: boolean;
    pages: Page[];
    key?: string;
};

type GlobalStateContextType = {
    globalState: GlobalStateType;
    setGlobalState: React.Dispatch<React.SetStateAction<Partial<GlobalStateType>>>;
};

const defaultState: GlobalStateType = { 
    user: null,
    apiHealth: null,
    theme: 'dark',
    loading: false,
    signedIn: false,
    pages: [],
    key: '',
}

const GlobalStateContext = createContext<GlobalStateContextType | undefined>(undefined);

export const useGlobalState = () => {
    const context = useContext(GlobalStateContext);
    if (!context) {
        throw new Error('useGlobalState must be used within a GlobalStateProvider');
    }
    return context;
};

export const GlobalStateProvider: React.FC<{children: ReactNode}> = ({ children }) => {
    const [globalState, _setGlobalState] = useState<GlobalStateType>(defaultState);

    const setGlobalState = (newState: React.SetStateAction<Partial<GlobalStateType>>) => {
        _setGlobalState((prevState) => ({
            ...prevState,
            ...newState,
        }));
    };

    return (
        <GlobalStateContext.Provider value={{ globalState, setGlobalState }}>
            {children}
        </GlobalStateContext.Provider>
    );
};
