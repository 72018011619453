import { useEffect } from 'react';
import api from '../services/api';
import { ITranslation, TranslationStatus } from '../types/ITranslation';

const useFetchUpdatedTranslations = (translationList: ITranslation[], setTranslationList: (translations: ITranslation[]) => void) => {
    useEffect(() => {
        const intervalId = setInterval(async () => {
            const curTranslations = [...translationList];
            const pendingTranslations = curTranslations.filter((t: ITranslation) => t.status === TranslationStatus.PENDING);
            if (!pendingTranslations.length) {
                clearInterval(intervalId);
                return;
            }

            let unchanged = true;
            await Promise.all(pendingTranslations.map(async (t: ITranslation) => {
                const res = await api.get(`/translations/${t._id}`);
                if (res?.status !== TranslationStatus.PENDING) {
                    const curTran = curTranslations.findIndex((ct: ITranslation) => ct._id === t._id);
                    if (curTran !== -1) {
                        curTranslations[curTran] = res;
                        unchanged = false;
                    }
                }
            }));
            if (!unchanged) {
                setTranslationList(curTranslations);
            }
        }, 2000);

        return () => clearInterval(intervalId);
    }, [translationList, setTranslationList]);
};

export default useFetchUpdatedTranslations;