import React, { useEffect, useRef } from 'react';

type Props = {
    children: React.ReactNode;
    onOutsideClick: () => void;
    className: string;
}

function OutsideClickHandler({ children, onOutsideClick, className }: Props) {
    const ref = useRef(null);

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !(ref.current as any).contains?.(event.target)) {
                onOutsideClick();
            }
        }

        // Bind the event listener
        document.addEventListener('mouseup', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mouseup', handleClickOutside);
        };
    }, [onOutsideClick]);

    return <div ref={ref} className={className}>{children}</div>;
}

export default OutsideClickHandler;

