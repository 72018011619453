import React, { FormEvent } from 'react';

import { ISingleTranslation, ITranslation, ITranslationBreakdown, TranslationStatus } from '../../types/ITranslation';
import { capitalizeFirstAll } from '../../utilities/capitalizeFirst';
import { speakText } from '../../utilities/language/speakText';

import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import {
    faVolumeUp,
    faPoop,
    faTrash,
} from '@fortawesome/free-solid-svg-icons';
import api from '../../services/api';
import { toast } from 'react-toastify';
import { formatDate } from '../../utilities/formatDate';

import styles from '../../styles/SingleTranslation.module.scss';

type Props = {
    translation: ITranslation;
    languageMap: any;
    // allLanguages: string[];
    onDelete?: (translationId: string) => void;
};

const SingleTranslation = ({
    translation,
    languageMap,
    // allLanguages,
    onDelete,
}: Props) => {

    const [showLanguages, setShowLanguages] = React.useState(false);
    const [openLanguages, setOpenLanguages] = React.useState({} as any);

    const isComplete = translation.status === TranslationStatus.COMPLETE
    const isErrored = translation.status === TranslationStatus.ERROR
    const isPending = translation.status === TranslationStatus.PENDING

    const getStatusStyle = () => {
        if (isComplete) return ''
        if (isErrored) return styles.error
        if (isPending) return styles.pending
        return ''
    }

    // close when new translation is added
    React.useEffect(() => {
        if (!translation) return;
        closeAllLanguages();
    }, [translation]);

    const openAllLanguages = () => {
        setShowLanguages(true)
        setOpenLanguages(translation.translations?.reduce((acc: any, t: ISingleTranslation, tIdx: number) => {
            acc[tIdx] = true;
            return acc;
        }, {}) || {});
    }

    const closeAllLanguages = () => {
        setShowLanguages(false)
        setOpenLanguages({});
    }

    const toggleAllLanguages = () => {
        if (showLanguages) {
            closeAllLanguages();
        } else {
            openAllLanguages();
        }
    }

    const toggleShowLanguages = (e: FormEvent) => {
        e.stopPropagation();
        setShowLanguages(!showLanguages)
    }

    const renderSpeakableText = (text: string, languageCode: string = 'en-US', textStyle={}) => {
        return (
            <span 
                className={styles.speakableText}
            >
                <span style={textStyle}>{text}</span>
                &nbsp;
                <span 
                    className={styles.speaker} 
                    onClick={(e) => {
                        e.stopPropagation();
                        speakText(text, languageCode)
                    }}
                >
                    <FontAwesomeIcon 
                        className={styles.speakerIcon} 
                        icon={faVolumeUp} 
                    />
                </span>
            </span>
        );
    }

    const renderPhrase = () => {
        return (
            <h2 
                className={`${styles.phrase} ${getStatusStyle()}`}
                onClick={() => setShowLanguages(!showLanguages)}
                onDoubleClick={() => {
                    toggleAllLanguages()
                }}
            >
                <span className={`${styles.arrow} text-lg`} onClick={toggleShowLanguages}>
                    {showLanguages ? '▼' : '►'}
                </span>
                
                {renderSpeakableText(translation.phrase, 'en-US')}

                <span className={`text-xs ml-1 ${styles.languageCount}`}>
                    ({translation.languages.length})
                </span>

                <FontAwesomeIcon 
                    className={styles.deleteIcon} 
                    icon={faTrash} 
                    onClick={async (e) => {
                        e.stopPropagation();
                        const deleteRes = await api.delete(`/translations/${translation._id}`);
                        if (deleteRes?.success) {
                            toast.success('Translation deleted!');
                            onDelete?.(translation._id);
                        }
                    }}
                />
                
            </h2>
        );
    }

    const renderStats = () => {
        return (
            <section
                className={`${styles.stats} ${getStatusStyle()}`}
            >
                <span>
                    model: <strong>{translation.model}</strong>
                </span>
                <span>
                    ellapsed ms: <strong>{translation.ellapsed_ms ?? '-'}</strong>
                </span>
                <span>
                    created at: <strong>{formatDate(translation.created_at)}</strong>
                </span>
                <span title={translation.languages.join(', ')}>
                    Languages: <strong>{translation.languages.length}</strong>
                </span>
                <span>
                    status: <strong>{translation.status}</strong>
                </span>


            </section>
        );
    }

    const renderTranslationByLanguage = (t: ISingleTranslation, tIdx: number) => {
        const showBreakdown = openLanguages[tIdx];
        return (<div 
            className={`${styles.languageSection} ${tIdx % 2 === 0 ? styles.evenDark : styles.oddDark}`}
        >
            <div 
                className={`${styles.language} ${showBreakdown ? styles.selectedLanguage : ''}`}
                onClick={() => {
                    setOpenLanguages({
                        ...openLanguages,
                        [tIdx]: !openLanguages[tIdx]
                    });
                }}
            >
                <span className={`${styles.arrow} text-sm`}>
                    {openLanguages[tIdx] ? '▼' : '►'}
                </span>
                <strong className='mr-1'>
                    {capitalizeFirstAll(t.language)}
                    {!t.isCorrectLanguage ? ` (req: ${t.requestedLanguage})` : ''}
                    :&nbsp;
                </strong>
                {renderSpeakableText(t.translation, languageMap[t.language.toLowerCase()])}
                {/* {t.translation} */}
            </div>
                {showBreakdown && t.breakdown.map((b: ITranslationBreakdown, bIdx: number) => {
                    return (
                        <div className={`${styles.breakdownSection} ${bIdx % 2 === 0 ? styles.even : styles.odd}`}>
                            <div className={`${styles.breakdown}`}>
                                {renderSpeakableText(b.word, languageMap?.[t.language.toLowerCase()])}
                                ⇨ {renderSpeakableText(b.translation, 'en-US')}
                                ⇨ ({renderSpeakableText(b.pronunciation)})
                            </div>
                        </div>
                    );
                })}
        </div>);
    }

    if (!translation) return <>No translation found</>;

    const allLanguages = Object.keys(languageMap);

    // sort translations by language in the order they come in allLanguages (from the api)
    const sortTranslationsByLanguage = (a: ISingleTranslation, b: ISingleTranslation) => {
        const aIdx = allLanguages.indexOf(a.language.toLowerCase());
        const bIdx = allLanguages.indexOf(b.language.toLowerCase());
        return aIdx - bIdx;
    }

    const sortedTranslations = translation.translations?.sort?.(sortTranslationsByLanguage) || [];

    return (
        <div className={`${styles.main} ${isPending ? styles.phrasePending : ''}`}>
            {renderPhrase()}
            {showLanguages && <>
                {renderStats()}
                {sortedTranslations.map(renderTranslationByLanguage)}
            </>}
        </div>
    );
}

export default SingleTranslation;