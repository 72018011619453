import React, { useEffect } from 'react';
import logo from './logo.svg';
import api from './services/api';
import { IUser } from './types/IUser';
import LoginForm from './components/Session/LoginForm/LoginForm';
import SignupForm from './components/Session/SignupForm/SignupForm';
import { GlobalStateProvider } from './common/globalState';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useGlobalState } from './common/globalState';
import LogoutWidget from './components/Session/LogoutWidget';
import GptQuestion from './components/GptQuestion';
import HealthInfo from './components/HealthInfo';
import SessionWidget from './components/Session/SessionWidget/SessionWidget';
import { Route, Routes } from 'react-router-dom';
import TitleBar from './components/TitleBar';

import TranslationsWidget from './components/Translations/TranslationsWidget';


import styles from './styles/App.module.scss'
import AssistantManager from './components/Assistant';
import VoiceTranslator from './components/VoiceTranslator/VoiceTranslator';

// import styles from './App.module.css';

const Loading = () => {
    return <div className='absolute w-full h-full z-50 bg-gray-900 bg-opacity-50 flex justify-center items-center'>
        <div className='bg-white rounded-lg p-4'>
            <h1 className='text-xl font-semibold'>Loading...</h1>
        </div>
    </div>
}

const App = () => {

    const { globalState, setGlobalState } = useGlobalState();

    const [loading, setLoading] = React.useState(false)

    const loadingRef = React.useRef(loading)
    loadingRef.current = loading

    const isAdmin = !!globalState.user?.isAdmin
    const isSignedIn = !!globalState.user?.name

    // get user if signed in
    useEffect(() => {

        // if (api.loggedIn()) return
        const loadUser = async () => {
            if (loadingRef.current) return
            loadingRef.current = true
            const res = await api.get('/users/self')
            console.log('self res:', res);

            if (res && res.user) {
                setGlobalState({
                    user: res.user,
                    signedIn: true,
                })
            }
        }
        loadUser()
    }, [loading])

    useEffect(() => {


        const pages = [
            {
                name: 'Home',
                path: '/',
                show: true,
            },
            {
                name: 'Assistant',
                path: '/assistant',
                show: isSignedIn,
                component: AssistantManager,
                zIndex: 3,
            },
            {
                name: 'GPT',
                path: '/gpt',
                show: isSignedIn,
                component: GptQuestion,
                zIndex: 2,
            },
            {
                name: 'Translations',
                path: '/translate',
                show: isSignedIn,
                component: TranslationsWidget,
                zIndex: 1,
            },
            {
                name: 'Voice Translator',
                path: '/voicetranslator',
                show: isSignedIn && isAdmin,
                component: VoiceTranslator,
                zIndex: 1
            },
        ]

        setGlobalState({ pages })
    }, [isSignedIn])

    return (
        <div className={`${styles.container} ${styles.backgroundImage}`}>
            <TitleBar />
            <main className={styles.main}>
                {/* <HealthInfo /> */}
                <Routes>
                    {globalState.pages.filter(page => page.show).map((page, index) => {
                        const PageComponent = page.component
                        if (!PageComponent) return null
                        return (
                            <Route key={index} path={page.path} element={<PageComponent />} />
                        )
                    })}
                </Routes>
            </main>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={true}
                rtl={false}
                pauseOnFocusLoss={true}
                draggable={true}
                pauseOnHover={true}
            />
            {globalState.loading && <Loading />}
        </div>
    );
}

export default App;
