import { Route, Routes } from "react-router-dom";
import HealthInfo from "./HealthInfo";
import SessionWidget from "./Session/SessionWidget/SessionWidget";
import GptQuestion from "./GptQuestion";
import { useGlobalState } from "../common/globalState";
// import TranslationsWidget from "./Translations/TranslationsWidget";

import styles from '../styles/TitleBar.module.scss'
import LoginForm from "./Session/LoginForm/LoginForm";
import { useState } from "react";
import MainMenu from "./MainMenu";
import OutsideClickHandler from "./OutsideClickHandler";

const TitleBar = () => {

    const { globalState, setGlobalState } = useGlobalState();

    const [showMainMenu, setShowMainMenu] = useState(false)
    const [showSessionMenu, setShowSessionMenu] = useState(false)

    const isAdmin = globalState.user?.isAdmin
    const isSignedIn = !!globalState.user?.name

    const renderMainMenuBtn = () => {
        return <div className={styles.mainMenuBtn}>
            <button className={`btn`} onClick={async () => {
                setShowMainMenu(!showMainMenu)
            }}>Menu</button>
        </div>
    }

    const renderHeaderLogo = () => {
        return <div className={styles.logo}>
            FBM
        </div>
    }

    const renderSessionMenuBtn = () => {
        return <div className={styles.sessionMenuBtn}>
            <button className={`btn`} onClick={async () => {
                setShowSessionMenu(!showSessionMenu)
            }}>{isSignedIn ? globalState.user!.name : `Login`}</button>
        </div>
    }

    const renderMainMenu = () => {
        if (!showMainMenu) return null
        return (
            <OutsideClickHandler 
                className={styles.mainMenu} 
                onOutsideClick={() => {
                    setShowMainMenu(false)
                }}
            >
                <MainMenu
                    close={() => setShowMainMenu(false)}
                />
            </OutsideClickHandler>
        )
    }

    const renderSessionMenu = () => {
        if (!showSessionMenu) return null
        return ( 
            <OutsideClickHandler 
                className={styles.sessionMenu} 
                onOutsideClick={() => {
                    setShowSessionMenu(false)
                }}
            >
                <SessionWidget 
                    close={() => setShowSessionMenu(false)} 
                    straightToLogin={true}
                />
            </OutsideClickHandler>
        )
    }
    
    return (
        <div className={`${styles.main}`}>
            {/* buttons in title bar */}
            {renderMainMenuBtn()}
            {renderHeaderLogo()}
            {renderSessionMenuBtn()}

            {/* menus */}
            {renderMainMenu()}
            {renderSessionMenu()}
            
        </div>
    );
}

export default TitleBar;